// Footer.js
import React from 'react';
import { Panel } from 'primereact/panel';
import 'primereact/resources/themes/saga-blue/theme.css';  // Theme
import 'primereact/resources/primereact.min.css';           // Core CSS
import 'primeicons/primeicons.css';                         // Icons
import './Footer.css'; // Import the CSS file for footer

const Footer = () => {
    return (
        <Panel className="footer" style={{ width: '100%' }}>
            {/* List of links - one to our about page. */}
            <ul>
                <li>
                    <a href="/">Home</a>
                </li>
                <li>
                    <a href="/about">About</a>
                </li>
                <li>
                    <a href="https://secure.actblue.com/donate/notgoingbackforharris">Not Going Back! Donate to Harris Walz 2024</a>
                </li>
            </ul>
        </Panel>
    );
};

export default Footer;
