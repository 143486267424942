// About.js
import React from 'react';
import { Card } from 'primereact/card';
import 'primereact/resources/themes/saga-blue/theme.css';  // Theme
import 'primereact/resources/primereact.min.css';           // Core CSS
import 'primeicons/primeicons.css';
const About = () => {
    return (
        <div style={{ padding: '20px' }}>
            <Card>
                {/* <img src="about.png" alt="About" style={{ width: '100%' }} /> */}
                <h2>About Us</h2>
                <img src="https://upload.wikimedia.org/wikipedia/commons/5/56/Donald_Trump_official_portrait.jpg" alt="Donald Trump" style={{ width: '200px', float: 'right', margin: '0 0 10px 10px' }} />
                {/* <h1>About TrumpOnce.com</h1> */}
                <p>Welcome to <strong>TrumpOnce.com</strong>, a unique website dedicated to showcasing the various things former President Donald Trump has said or done. By pulling random entries from our extensive database, we present an array of statements and actions attributed to Trump, offering you a glimpse into the past. Whether you're looking for a specific quote or just browsing for curiosity, TrumpOnce.com provides an intriguing experience.</p>
                <p>Some entries include references to sources, ensuring that you can explore the context behind these moments. It's an ongoing project and a labor of love from the creator, <a href="https://mastodon.social/@jenzi">@jenzi@mastodon.social</a>. We hope you find it informative, entertaining, or at the very least, interesting!</p>
            </Card>
        </div>
    );
};

export default About;