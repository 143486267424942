import React, { useState, useEffect } from 'react';
import quotes from './quotes.json'; // Adjust the path as necessary
import './App.css';
import { Card } from 'primereact/card';
import 'primereact/resources/themes/saga-blue/theme.css';  // Theme
import 'primereact/resources/primereact.min.css';           // Core CSS
import 'primeicons/primeicons.css';

const App = () => {
  const [quote, setQuote] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRandomQuote = () => {
      try {
        const randomIndex = Math.floor(Math.random() * quotes.length);
        setQuote(quotes[randomIndex]);
      } catch (err) {
        console.error('Error fetching the quote:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRandomQuote();
  }, []);

  useEffect(() => {
    const handleClick = (event) => {
      if (event.target.tagName !== 'A') {
        window.location.reload();
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container">
      <a href="https://secure.actblue.com/donate/notgoingbackforharris"><img src="harris.png" alt="Donate to Harris" style={{ width: '50%', display: 'block', margin: 'auto', padding: '20px' }} /></a>
      {/* <h1>Did You Know Trump Once...</h1> */}
      {quote && (
        <Card title="Did you know Trump once...">
          <p dangerouslySetInnerHTML={{ __html: quote.quote }}></p>
          {quote.links && quote.links.length > 0 && (
            <ul>
              {quote.links.map((link, index) => (
                <li key={index}>
                  <a href={link} target="_blank" rel="noopener noreferrer">Source</a>
                </li>
              ))}
            </ul>
          )}
        </Card>
      )}
      <p style={{ fontSize: '12pt' }}>Click/Tap to get another.</p>
    </div>
  );
};

export default App;