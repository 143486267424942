import React, { useState } from 'react';

const Submit = () => {
    const [quote, setQuote] = useState('');
    const [links, setLinks] = useState(['']);
    const [jsonOutput, setJsonOutput] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        const newQuote = {
            quote,
            links
        };

        const newJsonString = JSON.stringify(newQuote, null, 2);

        // Append the new JSON output to the existing one
        const updatedJsonOutput = jsonOutput
            ? `${jsonOutput},\n${newJsonString}`
            : newJsonString;

        setJsonOutput(updatedJsonOutput);
        //alert('Quote JSON generated! Scroll down to see the JSON output.');
    };

    const handleLinkChange = (index, value) => {
        const newLinks = [...links];
        newLinks[index] = value;
        setLinks(newLinks);
    };

    const addLinkField = () => {
        setLinks([...links, '']);
    };

    const removeLinkField = (index) => {
        const newLinks = links.filter((_, i) => i !== index);
        setLinks(newLinks);
    };

    return (
        <div style={{ padding: '20px' }}>
            <h2>Did You Know Trump Once...</h2>
            <p>Enter your example that ends the sentence and provide a source. This will build the JSON needed to include in the quotes.json file.</p>
            <form onSubmit={handleSubmit}>
                <div>
                    <textarea
                        value={quote}
                        onChange={(e) => setQuote(e.target.value)}
                        required
                        rows="4"
                        cols="50"
                    />
                </div>
                <div>
                    {links.map((link, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <input
                                type="url"
                                value={link}
                                onChange={(e) => handleLinkChange(index, e.target.value)}
                                required
                                style={{ width: '50%' }}
                            />
                            {links.length > 1 && (
                                <button type="button" onClick={() => removeLinkField(index)} style={{ marginLeft: '10px' }}>Remove</button>
                            )}
                        </div>
                    ))}
                    <button type="button" onClick={addLinkField} style={{ alignSelf: 'flex-start', marginBottom: '20px' }}>Add Another Link</button>
                </div>
                <div>
                    <button type="submit">Add Quote</button>
                </div>
            </form>
            {jsonOutput && (
                <div style={{ marginTop: '20px' }}>
                    <h3>Generated JSON:</h3>
                    <pre>{jsonOutput}</pre>
                </div>
            )}
        </div>
    );
};

export default Submit;