import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Submit from './Submit';
import reportWebVitals from './reportWebVitals';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Menubar } from 'primereact/menubar';
import 'primereact/resources/themes/saga-blue/theme.css';  // Theme
import 'primereact/resources/primereact.min.css';           // Core CSS
import 'primeicons/primeicons.css';
import Footer from './footer';// Icons
import About from './About';

const items = [
  {
    label: 'Home',
    icon: 'pi pi-fw pi-home',
    command: () => { window.location.href = "/" }
  },
  {
    label: 'About',
    icon: 'pi pi-fw pi-info',
    command: () => { window.location.href = "/about" }
  },
  {
    label: 'JSON Builder',
    icon: 'pi pi-fw pi-database',
    command: () => { window.location.href = "/submit" }
  }
];

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <div>
      <Menubar model={items} />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/submit" element={<Submit />} />
        <Route path="/about" element={<About />} />
      </Routes>
      {/* <Footer /> */}
    </div>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();